body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition:
        background-color 0.15s ease-in-out,
        color 0.15s ease-in-out;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.dark * {
    --background: 19 23 26;
    --foreground: 242 242 242;

    --muted: 37 49 57;
    --muted-foreground: 161 166 170;

    --popover: 27 34 40;
    --popover-foreground: 242 242 242;

    --border: 37, 49, 57;
    --input: 37, 49, 57;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 186 29 104;
    --primary-foreground: 210 40% 98%;

    --secondary: 37 49 57;
    --secondary-foreground: 250, 250, 250;

    --accent: 37, 49, 57, 0.5;
    --accent-foreground: 242 242 242;

    --destructive: 162 53 53;
    --destructive-foreground: 210 40% 98%;

    --confirmation: 0 123 56;
    --confirmation-foreground: 255 255 255;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --baseTextContrast: rgb(var(--foreground));
}

:root {
    --background: 250 250 250;
    --foreground: 0 0 0;

    --muted: 228 228 231;
    --muted-foreground: 113, 113, 122;

    --popover: 255 255 255;
    --popover-foreground: 33, 40, 45;

    --border: 228, 228, 231;
    --input: 228, 228, 231;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 186 29 104;
    --primary-foreground: 210 40% 98%;

    --secondary: 228 228 231;
    --secondary-foreground: 39, 39, 42;

    --accent: 228, 228, 231, 0.5;
    --accent-foreground: 0, 0, 0;

    --destructive: 162 53 53;
    --destructive-foreground: 210 40% 98%;

    --confirmation: 0 123 56;
    --confirmation-foreground: 255 255 255;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
        font-feature-settings:
            "rlig" 1,
            "calt" 1;
    }

    /* hide up and down arrows in number inputs */
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"] {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield !important;
    }
}

.construction-tape-bg {
    background-image: linear-gradient(
        45deg,
        rgb(var(--background)) 25%,
        rgb(var(--muted)) 25%,
        rgb(var(--muted)) 50%,
        rgb(var(--background)) 50%,
        rgb(var(--background)) 75%,
        rgb(var(--muted)) 75%,
        rgb(var(--muted)) 100%
    );
    background-size: 28.28px 28.28px;
    background-repeat: repeat;
}

ul {
    @apply list-disc;
    @apply ps-4;
}
ol {
    @apply list-decimal;
    @apply ps-4;
}

h1 {
    @apply text-3xl font-extrabold;
}

h2 {
    @apply text-2xl font-semibold;
}

h3 {
    @apply text-xl font-semibold;
}

h4 {
    @apply text-base font-semibold;
}

.mdxeditor a {
    @apply cursor-pointer text-primary underline transition-all duration-100 hover:text-primary/80;
}
.mdxeditor-toolbar {
    @apply rounded-none bg-transparent p-0 !important;
}
.mdxeditor,
.mdxeditor-popup-container {
    --baseBgActive: rgb(var(--muted)) !important;
    --baseBg: rgb(var(--background)) !important;
    --basePageBg: rgb(var(--background)) !important;
    --baseBase: rgb(var(--foreground)) !important;
    --baseBorder: rgb(var(--border)) !important;
    --accentBorder: rgba(0, 0, 0, 0) !important;
}
.mdxeditor-popup-container input {
    @apply bg-input text-foreground !important;
}
.mdxeditor-popup-container button {
    @apply cursor-pointer text-xs font-semibold !important;
}
.mdxeditor-popup-container button[type="submit"] {
    @apply bg-primary text-white transition-all duration-200 hover:bg-primary/80 !important;
}
.mdxeditor-popup-container button[type="reset"] {
    @apply bg-secondary text-foreground transition-all duration-200 hover:bg-secondary/80 !important;
}

.hourglass-animation {
    animation: hourglass-keyframes 7s infinite;
}

@keyframes hourglass-keyframes {
    0% {
        transform: rotate(0deg);
    }
    35% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    85% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.slide-in-bottom-variant-1 {
    @apply duration-300 animate-in fade-in-50 slide-in-from-bottom-1;
}
.slide-in-bottom-variant-2 {
    @apply duration-500 animate-in fade-in-50 slide-in-from-bottom-2;
}
.slide-in-bottom-variant-3 {
    @apply duration-700 animate-in fade-in-50 slide-in-from-bottom-4;
}
.slide-in-top-variant-1 {
    @apply duration-300 animate-in fade-in-50 slide-in-from-top-1;
}
.zoom-in-line {
    @apply duration-500 animate-in zoom-in-50;
}

@keyframes highlight-keyframes {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(8deg) translateX(3px);
    }
    45% {
        transform: rotate(-6deg) translateY(-2px);
    }
    80% {
        transform: rotate(8deg) translateX(2px);
    }
    100% {
        transform: rotate(0deg);
    }
}

.animate-highlight {
    animation: highlight-keyframes 0.8s none;
}

@keyframes conversation-bubble-left {
    0% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-12%);
    }
    60% {
        transform: translateY(-8%);
    }
    80% {
        transform: translateY(-10%);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes conversation-bubble-right {
    0% {
        transform: translateY(15%) scaleX(-1);
    }
    40% {
        transform: translateY(0) scaleX(-1);
    }
    60% {
        transform: translateY(2%) scaleX(-1);
    }
    80% {
        transform: translateY(3%) scaleX(-1);
    }
    100% {
        transform: translateY(15%) scaleX(-1);
    }
}
@keyframes conversation-bubble-rotate-left {
    0% {
    }
    40% {
        rotate: -3deg;
    }
    60% {
        rotate: -3deg;
    }
    100% {
    }
}
@keyframes conversation-bubble-rotate-right {
    0% {
    }
    40% {
        rotate: 4deg;
    }
    60% {
        rotate: 4deg;
    }
    100% {
    }
}

.animate-conversation-bubble-left {
    animation:
        conversation-bubble-left 2s ease-in-out reverse,
        conversation-bubble-rotate-left 5s;
    animation-iteration-count: infinite;
}
.animate-conversation-bubble-right {
    animation:
        conversation-bubble-right 1.8s ease-in-out reverse,
        conversation-bubble-rotate-right 4s;
    animation-iteration-count: infinite;
}
